import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, IconComponent, Shapeable, ContentNode as TContentNode } from '@shapeable/types';
import { Trend } from '@shapeable/collaborate-ore-types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentEntityIntro, ContentNode, EntityGrid, NextPageLink, classNames, useEntity, useView, DottedLine } from '@shapeable/web';
import { TabSpec, Tabs } from '../elements/tabs';
const cls = classNames('trend-main-layout');
import { compact } from 'lodash';
import { ExplorerView } from '../elements/explorer-view';

// -------- Types -------->

export type TrendView = 'overview' | 'library';

export type TrendMainLayoutProps = Classable & HasChildren & { 
  entity?: Trend;
};

export const TrendMainLayoutDefaultProps: Omit<TrendMainLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: 1em;
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(1)};
  `,
  desktop: css`
    gap: ${theme.UNIT(4)};
  `,
});

const SectionStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(5)};
  `,
});

const ContentTitleStyles = breakpoints({
  base: css`
    
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    
  `,
});


const TabsStyles = breakpoints({
  base: css`
    width: 100%;
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-size: 1.35em;
  `,
});

const NextLinkStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
    
  `,
});

const EntityGridStyles = breakpoints({
  base: css`
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Section: styled.section`${SectionStyles}`,
      ContentTitle: styled.h2`${ContentTitleStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,
      Intro: styled(ContentEntityIntro)`${IntroStyles}`,

    EntityGrid: styled(EntityGrid)`${EntityGridStyles}`,
    Tabs: styled(Tabs)`${TabsStyles}`,

    NextLink: styled(NextPageLink)`${NextLinkStyles}`,
};


export const TrendMainLayout: Shapeable.FC<TrendMainLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const { feedEntries = [], pages = [], posts = [], citations = [] } = entity;

  const libraryItems = compact([...posts, ...feedEntries, ...pages, ...citations ]);

  const hasLibrary = !!libraryItems.length;

  const { description } = entity;

  const tabs: TabSpec<TrendView>[] = compact([
    {
      id: 'overview',
      label: 'Overview',
    },
    hasLibrary &&
    {
      id: 'library',
      label: 'Library',
    },
  ]);

  const { activeView, setActiveView } = useView<TrendView>(`trend-${entity.slug}`, 'overview');


  return (
    <My.Container className={cls.name(className)}>
      {
        tabs.length > 1 &&
        <My.Tabs onTabClick={setActiveView} activeTab={activeView} tabs={tabs} variant='section' />
      }
      <ExplorerView>
      {
        activeView === 'overview' && 
        <My.Section>
          <My.Intro entity={entity} />
          <My.Description entity={description as TContentNode} />
        </My.Section>
      }
      {
        activeView === 'library' && 
        <My.Section>
          <My.EntityGrid items={libraryItems} />
        </My.Section>
      }
      <DottedLine height={2} />
      <My.NextLink />
      </ExplorerView>
    </My.Container>
  )
};

TrendMainLayout.defaultProps = TrendMainLayoutDefaultProps;
TrendMainLayout.cls = cls;